<template>
  <div>
    <el-dialog visible append-to-body :show-close="false" custom-class="rounded close-modal" width="432px">
      <template #title>
        <div>
          <div class="d-flex justify-content-between">
            <h2 class="title">{{ $t('requests.closeModal.title') }}</h2>
            <Button type="icon" class="p-0 close-button" @click="$emit('close')">
              <CloseIcon :size="20" />
            </Button>
          </div>
          <p>
            {{ request.sourceBusiness.name }}
            <ExchangeIcon class="mx-1" />
            {{ request.targetBusiness.name }}
          </p>
        </div>
      </template>
      <div class="details text-typography-primary mb-4">
        <h3 class="mb-4">{{ $t('requests.snoozeModal.requestDetails') }}</h3>
        <p>{{ request.details }}</p>
      </div>
      <div class="answer text-typography-primary">
        <p>{{ $t('requests.closeModal.answer') }}</p>
        <el-input
          v-model="answer"
          class="details-area mt-1"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 10 }"
        />
      </div>
      <Inquiries :inquiries="inquiriesWithRequestId">
        <template #title>
          <h3 class="mb-4 mt-6">{{ $t('requests.snoozeModal.inquiries') }}</h3>
        </template>
        <template #inquiryInput>
          <InquiryInput class="mt-4 mb-2" :inquiry.sync="requestInquiryInput" @create-inquiry="createInquiryHandler" />
        </template>
      </Inquiries>
      <template #footer>
        <div class="d-flex justify-content-end">
          <Button type="secondary" @click="$emit('close')">{{ $t('requests.closeModal.buttons.cancel') }}</Button>
          <Button :disabled="!answer?.length" @click="handleCloseRequest">{{
            $t('requests.closeModal.buttons.closeRequest')
          }}</Button>
        </div>
      </template>
    </el-dialog>
    <ActionsModal
      v-if="confirmRequestCloseToggle"
      :toggle-dialog="confirmRequestCloseToggle"
      :title="$t('requests.snoozeModal.inquiryNotSavedTitle')"
      :content="$t('requests.snoozeModal.inquiryNotSavedContent')"
      :confirm-button-text="$t('requests.snoozeModal.save')"
      :cancel-button-text="$t('requests.snoozeModal.skip')"
      dialog-type="info"
      @on-cancel="$emit('close-request', answer)"
      @on-close="confirmRequestCloseToggle = false"
      @on-confirm="
        () => {
          createInquiryHandler();
          $emit('close-request', answer);
        }
      "
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { isEmpty } from 'ramda';

import { Button, ActionsModal } from '@/modules/core';
import { ExchangeIcon, CloseIcon } from '@/assets/icons';

import { useCreateInquiry } from '../compositions/createInquiry';
import InquiryInput from './InquiryInput';
import Inquiries from './Inquiries';

export default {
  components: { Button, ActionsModal, InquiryInput, Inquiries, ExchangeIcon, CloseIcon },
  props: {
    request: { type: Object, required: true },
  },
  setup(props) {
    const { mutate: createInquiry } = useCreateInquiry();

    return {
      answer: ref(),
      requestInquiryInput: ref(),
      confirmRequestCloseToggle: ref(false),
      createInquiry,
      inquiriesWithRequestId: computed(() =>
        (props.request.inquiries ?? []).map((inquiry) => ({ ...inquiry, requestId: props.request.id }))
      ),
    };
  },
  methods: {
    handleCloseRequest() {
      if (!this.requestInquiryInput || isEmpty(this.requestInquiryInput)) {
        this.$emit('close-request', this.answer);
      } else {
        this.confirmRequestCloseToggle = true;
      }
    },
    createInquiryHandler() {
      this.createInquiry({
        data: { content: this.requestInquiryInput },
        requestId: this.request.id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .close-modal {
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }
}

.details {
  word-break: break-word;
}

.close-button {
  color: $typography-secondary;
  &:hover {
    background: #fff !important;
    color: $typography-primary !important;
  }
}

.answer {
  word-break: break-word;
}
</style>

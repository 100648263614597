<template>
  <div>
    <div class="request-inquiries-wrapper text-typography-primary">
      <slot name="title" />
      <slot name="inquiryInput" />
      <div v-for="(inquiry, index) in requestInquiries" :key="index">
        <div class="request-inquiries-list d-flex justify-content-between pt-2 gap-5">
          <p class="request-inquiries-list-item-timestamp text-typography-secondary">
            {{ getDate(inquiry.timestamp) }}
          </p>
          <span
            v-if="isAdmin && hasRequestManage"
            class="request-inquiries-list-item-delete"
            @click="() => setInquiryForDeletion(inquiry)"
          >
            <CloseIcon width="16" height="16" />
          </span>
        </div>
        <div v-if="Array.isArray(inquiry.content)" class="text-start text-break">
          <p v-for="(line, i) in inquiry.content" :key="i">{{ line }}</p>
        </div>
        <p v-else class="text-start text-break">{{ inquiry.content }}</p>
      </div>
    </div>
    <ActionsModal
      v-if="confirmDeleteInquiryToggle"
      :toggle-dialog="confirmDeleteInquiryToggle"
      :title="$t('requests.snoozeModal.confirmDeletionTitle')"
      :content="$t('requests.snoozeModal.confirmDeletionContent')"
      :confirm-button-text="$t('requests.snoozeModal.delete')"
      :cancel-button-text="$t('requests.snoozeModal.cancel')"
      dialog-type="danger"
      @on-cancel="confirmDeleteInquiryToggle = false"
      @on-close="confirmDeleteInquiryToggle = false"
      @on-confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { CloseIcon } from '@/assets/icons';
import { ActionsModal, formatDateTime } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { useUpdateReconciliation } from '@/modules/reconciliation';
import { useGlobalPermissions } from '@/modules/permissions';

import { useDeleteInquiry } from '../compositions/deleteInquiry';

export default {
  components: { CloseIcon, ActionsModal },
  props: {
    inquiries: { type: Array, required: true },
  },
  setup(props) {
    const { isAdmin } = useUser();
    const { mutate: deleteInquiry } = useDeleteInquiry();
    const { mutate: updateReconciliation } = useUpdateReconciliation();
    const { hasRequestManage } = useGlobalPermissions();

    return {
      isAdmin,
      requestInquiries: computed(() => [...props.inquiries].sort((a, b) => b.timestamp - a.timestamp)),
      requestInquiryForDeletion: ref(null),
      confirmDeleteInquiryToggle: ref(false),
      deleteInquiry,
      updateReconciliation,
      hasRequestManage,
    };
  },
  methods: {
    setInquiryForDeletion(inquiry) {
      this.requestInquiryForDeletion = inquiry;
      this.confirmDeleteInquiryToggle = true;
    },
    handleConfirm() {
      if (this.requestInquiryForDeletion.reconciliationId) {
        this.updateReconciliation({
          id: this.requestInquiryForDeletion.reconciliationId,
          patchParams: { actionsPerformed: '' },
        });
      } else {
        this.deleteInquiry({
          requestId: this.requestInquiryForDeletion.requestId,
          inquiryId: this.requestInquiryForDeletion.id,
        });
      }
      this.confirmDeleteInquiryToggle = false;
      this.requestInquiryForDeletion = null;
    },
    getDate(timestamp) {
      return timestamp ? formatDateTime(timestamp) : this.$t('requests.dateMissing');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.request-inquiries-wrapper {
  .request-inquiries-input {
    ::v-deep input.el-input__inner {
      width: 98%;
    }
  }

  .request-inquiries-action-button {
    height: 40px;
    width: 40px;
    background: $gray;

    .send-icon {
      [dir='rtl'] & {
        transform: scaleX(1);
      }

      [dir='ltr'] & {
        transform: scaleX(-1);
      }

      &.active {
        color: white;
      }
    }

    &:hover {
      background: $gray;
    }
    &.active {
      background: $active-color;
    }
  }

  .request-inquiries-list {
    .request-inquiries-list-item-timestamp {
      min-width: 8.5rem;
    }

    .request-inquiries-list-item-delete > svg {
      color: $typography-secondary;
      cursor: pointer;
      width: 1.25rem;
      opacity: 0;
    }

    &:hover {
      .request-inquiries-list-item-delete {
        > svg {
          opacity: 100;
          &:hover {
            color: $typography-primary;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="request-inquiries-input-wrapper d-flex">
    <el-input
      :value="requestInquiryInput"
      class="request-inquiries-input"
      :placeholder="$t('requests.snoozeModal.inquiryInputPlaceholder')"
      @keyup.native.enter="handleCreateInquiry"
      @input="handleInput"
    />
    <Button
      type="icon"
      class="request-inquiries-action-button p-0"
      :class="{ active: requestInquiryInput }"
      :disabled="!requestInquiryInput"
      @click="handleCreateInquiry"
    >
      <SendIcon class="send-icon" :class="{ active: requestInquiryInput }" />
    </Button>
  </div>
</template>

<script>
import { ref } from 'vue';

import { SendIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

export default {
  components: { Button, SendIcon },
  props: {
    inquiry: { type: String, default: null },
  },
  setup() {
    return { requestInquiryInput: ref() };
  },
  methods: {
    handleInput(value) {
      this.requestInquiryInput = value;
      this.$emit('update:inquiry', this.requestInquiryInput);
    },
    handleCreateInquiry() {
      this.$emit('create-inquiry');
      this.requestInquiryInput = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.request-inquiries-input-wrapper {
  .request-inquiries-input {
    ::v-deep input.el-input__inner {
      width: 98%;
    }
  }

  .request-inquiries-action-button {
    height: 40px;
    width: 40px;
    background: $gray;

    .send-icon {
      [dir='rtl'] & {
        transform: scaleX(1);
      }

      [dir='ltr'] & {
        transform: scaleX(-1);
      }

      &.active {
        color: white;
      }
    }

    &:hover {
      background: $gray;
    }
    &.active {
      background: $active-color;
    }
  }
}
</style>

<template>
  <div>
    <PageLayout class="container">
      <template #header>
        <h1>{{ $t('requests.title') }}</h1>
      </template>
      <template #tabs>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="handleTabClick" />
      </template>
      <router-view :businesses="businesses" />
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { ref, watch, getCurrentInstance } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { useBusinesses } from '@/modules/auth';

export default {
  components: { PageLayout, Tabs },
  setup() {
    const root = getCurrentInstance().proxy;
    const activeTab = ref(null);
    const { businesses } = useBusinesses();

    const tabs = [
      {
        text: root.$t('requests.tabs.open'),
        name: 'open',
        path: 'open',
      },
      {
        text: root.$t('requests.tabs.inReview'),
        name: 'inReview',
        path: 'in-review',
      },
      {
        text: root.$t('requests.tabs.closed'),
        name: 'closed',
        path: 'closed',
      },
    ];

    const handleTabClick = (tabIndex) => {
      const selectedTab = tabs[tabIndex];
      root.$router.push({ path: selectedTab.path });
    };

    watch(
      () => root.$route.name,
      (name) => {
        activeTab.value = tabs.findIndex((t) => t.name === name);
      },
      { immediate: true }
    );

    return {
      tabs,
      activeTab,
      handleTabClick,
      businesses,
    };
  },
};
</script>
<style scoped>
.container {
  min-width: 1480px;
}
</style>

<template>
  <el-popover v-model="showContactCard" trigger="click" placement="bottom" popper-class="p-0">
    <ContactCard
      v-if="showContactCard"
      :user="{ type: 'tenant', payload: { worksAt: business.id } }"
      :customer="business"
      :supplier="business"
      :token="token"
      :business-id="tenantId"
    />
    <template #reference>
      <TruncatedText>
        <Button type="link" class="text-typography-primary p-0">
          <span>{{ business.name }}</span>
        </Button>
      </TruncatedText>
    </template>
  </el-popover>
</template>

<script>
import { ref } from 'vue';

import { ContactCard } from '@/modules/contact';
import { Button, TruncatedText } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

export default {
  name: 'BusinessNameWithPopover',
  components: {
    ContactCard,
    Button,
    TruncatedText,
  },
  props: {
    business: { type: Object, required: true },
    tenantId: { type: String, required: true },
  },
  setup() {
    const { token } = useTenancy();
    const showContactCard = ref(false);
    return { token, showContactCard };
  },
};
</script>
